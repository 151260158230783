<template>
  <div>
    <c-card :title="`소방배치도 정보`" class="cardClassDetailForm firefighting-org-card">
      <template slot="card-button">
        <q-btn-group outline >
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div ref="org" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import * as d3 from 'd3';
import { OrgChart } from 'd3-org-chart';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'selfDefenseFireBrigade',
  components: {
  },
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: null,
        processCd: null,
        sopFireFightingTypeCd: null,
        disposeFlag: 'N'
      },
      chartReference: null,
      orgs: [
        { 
          name: '홍길동', id: '1', parentId: '', jobName: '공장장', mobileNo: '010-1111-1111',
        },
        { 
          name: '김길동', id: '2', parentId: '1', jobName: '안전관리자', mobileNo: '010-1111-1111',
        },
        { 
          name: '최길동', id: '3', parentId: '1', jobName: '팀장', mobileNo: '010-1111-1111',
        },
        { 
          name: '이길동', id: '4', parentId: '1', jobName: '환경책임자', mobileNo: '010-1111-1111',
        },
        { 
          name: '윤길동', id: '5', parentId: '3', jobName: '팀원', mobileNo: '010-1111-1111',
        },
        { 
          name: '안전환경팀', id: '6', parentId: '3', jobName: '팀원', mobileNo: '',
        },
        { 
          name: '성길동', id: '7', parentId: '3', jobName: '팀원', mobileNo: '010-1111-1111',
        },
        { 
          name: '지정해주세요.', id: '8', parentId: '3', jobName: '', mobileNo: '',
        },
      ],
      compact: 0,
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.fft.fireFightingMap.list.url
      this.saveUrl = transactionConfig.fft.fireFightingMap.insert.url;
      // this.deleteUrl = transactionConfig.fft.fireFightingMap.delete.url;
      // code setting
      // list setting
      this.getList();
      this.setSize();
    },
    getList() {
      if (!this.chartReference) {
        this.chartReference = new OrgChart();
      }
      this.chartReference
        .container(this.$refs.org) // node or css selector
        .data(this.orgs)
        .initialExpandLevel(2)
        .svgHeight(500)
        .nodeHeight((d) => 120)
        .nodeWidth((d) => {
          return 220;
        })
        .childrenMargin((d) => 50)
        .compactMarginBetween((d) => 65)
        .compactMarginPair((d) => 100)
        // .neightbourMargin((a, b) => 25)
        .siblingsMargin((d) => 25)
        .compact(false)
        .buttonContent(({ node, state }) => {
          return `<div style="px;color:#716E7B;border-radius:5px;padding:4px;font-size:10px;margin:auto auto;background-color:white;border: 1px solid #E4E2E9"> <span style="font-size:9px">${
            node.children
              ? `<i class="fas fa-angle-up"></i>`
              : `<i class="fas fa-angle-down"></i>`
          }</span> ${node.data._directSubordinates}  </div>`;
        })
        .nodeContent(function (d, i, arr, state) {
          let isTop = ['1', '2', '4'].indexOf(d.data.id) > -1;
          const color = '#F2EFE5';
          const background = {
            card: isTop ? '#FFAF45' : color,
            avatar: isTop ? '#F2EFE5' : color,
          }
          const fontColor = {
            name: '#08011E',
            job: '#08011E',
          }
          return `
            <div style="font-family: 'Inter', sans-serif;background-color:${background.card}; position:absolute;margin-top:-1px; margin-left:-1px;width:${d.width}px;height:${d.height}px;border-radius:10px;border: 1px solid #E4E2E9">
              <div style="background-color:${background.avatar};position:absolute;margin-top:-25px;margin-left:${15}px;border-radius:100px;width:50px;height:50px;" ></div>
              <span class="material-icons" style="position:absolute;margin-top:-20px;margin-left:${20}px;border-radius:100px;width:40px;height:40px;font-size:50px">
                account_circle
              </span>

              <div style="font-size:15px;color:${fontColor.name};margin-left:20px;margin-top:32px"> ${
                d.data.name
              } </div>
              <div style="color:${fontColor.job};margin-left:30px;margin-top:3px;font-size:10px;"> ${
                d.data.jobName
              } </div>
              <div style="color:#08011E;text-align:center;font-size:16px;position: absolute;bottom: 0;width: 100%;height: 35px;border-bottom-right-radius: 10px;border-bottom-left-radius: 10px;"> 
                <span class="material-icons">phone</span> ${d.data.mobileNo} 
              </div>
            </div>`;
        })
        .onNodeClick((d) => this.setOrg(d.data))
        .render()
        .fit();
    },
    setOrg(data) {
      console.log(data)
    },
    setSize() {
      console.log(window.innerHeight - 150)
      this.chartReference.svgHeight(window.innerHeight - 150).render().fit()
    },
  }
};
</script>
<style lang="sass">
.firefighting-org-card
  .customCardbody
    margin: 0 !important
    padding: 0 !important
</style>